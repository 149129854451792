import { Link } from "react-router-dom";
import {useEffect, useState, useRef} from 'react';
import header from "../css/navbar.module.css";

export default function Navbar() {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 720)

	const handleResize = () => {
		if (window.innerWidth < 720) {
				setIsMobile(true)
		} else {
				setIsMobile(false)
		}
	}

	useEffect(() => {
		window.addEventListener("resize", handleResize)
	}, [])

	return (
		<>
		{isMobile ?
		<header className={header.header}>
			<img src={require("../../assets/logo.png")} className={header.logo} alt="KnowMore Hackathon logo"/>
			<div className={header.button}>
				<Link to="/#home" className={header.links}>Home</Link>
				<Link to="/faq" className={header.links}>FAQ</Link>
				<Link
					to="/#login"
					className={header.links + " " + header.signin}
				>
					Sign Up
				</Link>
			</div>
		</header>
		:
		<header className={header.header}>
			<img src={require("../../assets/logo.png")} className={header.logo} alt="KnowMore Hackathon logo"/>
			<div className={header.button}>
				<Link to="/#home" className={header.links}>Home</Link>
				<Link to="/#about" className={header.links}>About</Link>
				<Link to="/#sponsors" className={header.links}>Sponsors</Link>
				<Link to="/faq" className={header.links}>FAQ</Link>
				<Link
					to="/#login"
					className={header.links + " " + header.signin}
				>
					Sign Up
				</Link>

			</div>
		</header>
		}
		</>
	);
}
